import { alertController, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonList, IonPage, IonTitle, IonToggle, IonToolbar, loadingController, } from '@ionic/vue';
import { arrowBack } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { clearAllDBs, clearAllRecords } from '../services/db/db';
import { setThemeMode, isDark } from '../services/theme';
import { OfflineError } from '../utils/errors';
import { logout } from '../utils/oidc';
import env from '../utils/env';
export default defineComponent({
    components: {
        IonButton,
        IonButtons,
        IonContent,
        IonHeader,
        IonIcon,
        IonItem,
        IonItemDivider,
        IonItemGroup,
        IonLabel,
        IonList,
        IonPage,
        IonTitle,
        IonToggle,
        IonToolbar,
    },
    setup() {
        const router = useRouter();
        const { t } = useI18n();
        async function handleLogout() {
            console.info('Logout user');
            console.group();
            try {
                await clearAllDBs();
                await logout();
                console.groupEnd();
            }
            catch (err) {
                const alert = await alertController.create({});
                if (err instanceof OfflineError) {
                    await clearAllDBs();
                }
                else {
                    alert.message = `ein unerwarteter Fehler ist aufgetreten`;
                    await alert.present();
                }
                console.groupEnd();
            }
        }
        function handleRedirectToLivingApps() {
            window.location.href = 'https://my.living-apps.de';
        }
        async function handleClearAllRecords() {
            const alert = await alertController.create({
                message: 'Sind Sie sich sicher, dass sie alle offline Daten von dem Gerät löschen wollen?',
                buttons: [
                    {
                        text: 'ja',
                        handler: async () => {
                            const loading = await loadingController.create({
                                message: 'Leere Datenbanken'
                            });
                            await loading.present();
                            const { error } = await clearAllRecords();
                            await loading.dismiss();
                            if (error) {
                                const alert = await alertController.create({
                                    message: 'Es ist ein Fehler augetreten',
                                    buttons: [{
                                            text: 'ok'
                                        }]
                                });
                                await alert.present();
                            }
                            else {
                                router.replace('/');
                            }
                        }
                    },
                    {
                        text: 'nein',
                    }
                ]
            });
            await alert.present();
        }
        return {
            arrowBack,
            env,
            handleClearAllRecords,
            handleLogout,
            handleRedirectToLivingApps,
            isDark,
            isIosOnly: env.iOsOnly,
            router,
            setThemeMode,
            t,
        };
    },
});
